import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button,
    Container,
    Row,
    Col
} from 'reactstrap';
import ServiceUpload from '../../services/uploads/UploadsServices.js';
import {AlertContext} from "../../providers/AlertProvider";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import UserInfo from "../../core/UserInfo";

class uploadsDrs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmation: false,
            showerror: false,
            messageError: "",
            email: "",
        }
    }




    componentDidMount = () => {
        let userInfo = new UserInfo();
        let email = userInfo.Email != undefined ? userInfo.Email : userInfo.UPN;
        this.setState({email: email})
    }


    handleInputChange(target) {
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;
        this.setState({
            formFilter: {...this.state.formFilter, [field]: value},
            invalid: {...this.state.invalid, [field]: target.required && !value},
        });
    }    
    handleInputBlur = target => {
        const field = target.name;
        this.setState({
            invalid: {...this.state.invalid, [field]: target.required && !this.state.formFilter[field]},
        });
    };

    UploadTenantMailboxUsage = async (e) => {
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);
        
        let email = this.state.email;
        ServiceUpload.PostTenantMailboxUsage(formData, email, (data) => {
          this.showSuccessMessage("Upload realizado com sucesso");
          this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }
    UploadTenantOfficeLicenses = async (e) => {
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);

        let email = this.state.email;
        ServiceUpload.PostTenantOfficeLicenses(formData, email,  (data) => {
          this.showSuccessMessage("Upload realizado com sucesso");
          this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }
    UploadTenantProductActivity = async (e) => {
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);
        
        let email = this.state.email;
        ServiceUpload.PostTenantProductActivity(formData, email,  (data) => {
          this.showSuccessMessage("Upload realizado com sucesso");
          this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }
    UploadTenantProductUsage = async (e) => {
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);
        
        let email = this.state.email;
        ServiceUpload.PostTenantProductUsage(formData, email,  (data) => {
          this.showSuccessMessage("Upload realizado com sucesso");
          this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }
    UploadUserState = async (e) => {
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);
          
        let email = this.state.email;
        ServiceUpload.PostUserState(formData, email,  (data) => {
          this.showSuccessMessage("Upload realizado com sucesso");
          this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }
    UploadAlldrs = async (e) => {
        var formData = new FormData();
        var files = Array.from(e.target.files);
        files.map(x => formData.append('File', x));
        
        let email = this.state.email;
          ServiceUpload.PostAlldrs(formData, email,  (data) => {
            if(data.success){
                this.showSuccessMessage("Upload realizado com sucesso");
                this.setState({showConfirmation: true}) 
            }else{
                this.showErrorMessage(data.result);
                this.setState({showerror: true, messageError: data.result}) 
            }

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 
    }

    render() {

        return (
            <main className="h-100 pageuploads">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <Container fluid className="h-100 animated fadeIn">
                <Button color="primary" className="btn-voltar" onClick={() => window.location.href = "/"} >Voltar</Button>
                <Row>
                    <Col  style={{marginTop: '11em'}}>
                        <div class="body-container-wrapper">
                            <div class="body-container">
                                <div class="text-center">                
                                    <i class="fa fa-cloud" aria-hidden="true"></i>
                                    <h1>Faça todos os <strong>Uploads</strong> dos arquivos<strong> CSV's </strong>abaixo</h1>
                                </div>
                              </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div class="body-container-wrapper">
                            <div class="body-container">
                                <div class="text-center">                
                                    <label  htmlFor="upload-PostAlldrs" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                    <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                    <div id="drop">
                                        <input  onChange={e => this.UploadAlldrs(e)} id="upload-PostAlldrs" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                    </div>
                                    </form>
                               
                                </div>
                              </div>
                        </div>
                    </Row>
                <Row style={{textAlign: 'center'}} className="justify-content-center">
                    <a class="" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        <h2 style={{color: 'white'}}>Ou clique  <strong>Aqui</strong> para fazer o Upload dos arquivos<strong> CSV </strong>separadamente</h2>
                    </a>
                    <div class="collapse" id="collapseExample">
                        <Row className="internasUp">
                            <Col xs={6} sm={6}>
                            <div class="body-container-wrapper">
                                <div class="body-container">
                                    <div class="text-center">                
                                        <h1><strong>01DR</strong></h1>
                                        <label  htmlFor="upload-photo" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                        <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                        <div id="drop">
                                            <input  onChange={e => this.UploadTenantMailboxUsage(e)} id="upload-photo" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                        </div>
                                        </form>

                                    </div>
                                  </div>
                            </div>
                            </Col>
                            <Col xs={6} sm={6}>
                            <div class="body-container-wrapper">
                                <div class="body-container">
                                    <div class="text-center">                
                                        <h1><strong> 02DR </strong></h1>
                                        <label  htmlFor="upload-TenantOfficeLicenses" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                        <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                        <div id="drop">
                                            <input  onChange={e => this.UploadTenantOfficeLicenses(e)} id="upload-TenantOfficeLicenses" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                        </div>
                                        </form>

                                    </div>
                                  </div>
                            </div>
                            </Col>
                            <Col xs={3} sm={3}>
                            <div class="body-container-wrapper">
                                <div class="body-container">
                                    <div class="text-center">                
                                        <h1><strong> 03DR </strong></h1>
                                        <label  htmlFor="upload-TenantProductActivity" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                        <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                        <div id="drop">
                                            <input  onChange={e => this.UploadTenantProductActivity(e)} id="upload-TenantProductActivity" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                        </div>
                                        </form>

                                    </div>
                                  </div>
                            </div>
                            </Col>
                            <Col xs={3} sm={3}>
                            <div class="body-container-wrapper">
                                <div class="body-container">
                                    <div class="text-center">                
                                        <h1><strong> 04DR </strong></h1>
                                        <label  htmlFor="upload-TenantProductUsage" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                        <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                        <div id="drop">
                                            <input  onChange={e => this.UploadTenantProductUsage(e)} id="upload-TenantProductUsage" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                        </div>
                                        </form>

                                    </div>
                                  </div>
                            </div>
                            </Col>
                            <Col xs={3} sm={3}>
                            <div class="body-container-wrapper">
                                <div class="body-container">
                                    <div class="text-center">                
                                        <h1><strong> 05DR </strong></h1>
                                        <label  htmlFor="upload-UserState" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                        <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                        <div id="drop">
                                            <input  onChange={e => this.UploadUserState(e)} id="upload-UserState" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                        </div>
                                        </form>

                                    </div>
                                  </div>
                            </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
                </Container>
               
                <SweetAlert
                    show={this.state.showConfirmation}
                    title="Sucesso"
                    type='success'
                    showCancel
                    showCloseButton
                    confirmBtnText='Ok'
                    confirmButtonColor='#d6486b'
                    confirmBtnCssClass='customBtn'
                    onConfirm={() => this.setState({showConfirmation: false})}
                    showCancel={false}
                    onEscapeKey={() => this.setState({showConfirmation: false})}>
                    Upload CSV CATC Realizado com Sucesso
                </SweetAlert>

                <SweetAlert
                    show={this.state.showerror}
                    title="Erro"
                    type='error'
                    showCancel
                    showCloseButton
                    confirmBtnText='Ok'
                    confirmButtonColor='#d6486b'
                    confirmBtnCssClass='customBtn'
                    onConfirm={() => this.setState({showerror: false})}
                    showCancel={false}
                    onEscapeKey={() => this.setState({showerror: false})}>
                    {this.state.messageError}
                </SweetAlert>
            </main>
        );
    }
}

export default withRouter(uploadsDrs);