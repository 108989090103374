import React, { Component } from 'react'

class Footer extends Component{
	render(){
		return(
			<footer>
				<p>Big Brain Education © 2019</p>
			</footer>
		)
	}
}

export default Footer;