import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import UploadPage from './components/Upload/uploadPage';
import UploadPageMinecraft from './components/Upload/uploadPageMinecraft';
import UploadsDrs from './components/Upload/uploadsDrs';
import LayoutDefault from './components/Layouts/LayoutDefault';
import PaginaPowerBI from './components/Home/PaginaPowerBI';

// import LearningPlans from './components/LearningPlans/LearningPlans'
// import LearningPlansForm from './components/LearningPlans/LearningPlansForm'

class Routes extends Component {
    render(){ 
        return(
            <BrowserRouter>
            <Switch>
                <Route exact path="/uploadPageMinecraft">
                    <LayoutDefault>
                        <UploadPageMinecraft />
                    </LayoutDefault>
                </Route>
                <Route exact path="/uploadsCATC">
                    <LayoutDefault>
                        <UploadPage/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/">
                    <LayoutDefault>
                        <PaginaPowerBI/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/uploadsDrs">
                    <LayoutDefault>
                        <UploadsDrs/>
                    </LayoutDefault>
                </Route>
                {/*
                <Route exact path="/roteiros-de-aprendizagem/:id">
                    <LayoutDefault>
                        <LearningPlansForm/>
                    </LayoutDefault>
                </Route> */}
            </Switch>
        </BrowserRouter>

        );

    }
}

export default Routes;


