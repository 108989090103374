import React, { Component } from 'react';
import { Nav, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Menu from './Menu';
import { DropDownContent } from './DropDownContent';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setarMenu: window.location.pathname.split('/')[1],
            isOpen: false,
            name: [
                {
                    id: 1,
                    nome: 'Upload Arquivos CSV CATC',
                    title: 'UploadPage',
                    path: '/uploadsCATC',
                    ico: 'fab fa-leanpub',
                },
                {
                    id: 2,
                    nome: 'Upload Arquivos CSV Minecraft Education',
                    title: 'Upload Page Minecraft',
                    path: '/uploadPageMinecraft',
                    ico: 'fab fa-leanpub',
                },
                {
                    id: 3,
                    nome: 'Dados Aplicativos Microsoft',
                    title: 'Dados Aplicativos Microsoft',
                    path: '/uploadsDrs',
                    ico: 'fas fa-cubes',
                },
              ]
        };
        this.menuItens = new Menu();
        this.menuItens = this.menuItens.Items;
    }
    setMenu = event => {
        this.setState({ setarMenu: event.target.title });
    };  
    render() {
        Nav.propTypes = {
            tabs: PropTypes.bool,
            pills: PropTypes.bool,
            card: PropTypes.bool,
            justified: PropTypes.bool,
            fill: PropTypes.bool,
            vertical: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            horizontal: PropTypes.string,
            navbar: PropTypes.bool,
            tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            // pass in custom element to use
        };
        NavLink.propTypes = {
            disabled: PropTypes.bool,
            active: PropTypes.bool,
            // pass in custom element to use
            //tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            // ref will only get you a reference to the NavLink component, use innerRef to get a reference to the DOM element (for things like focus management).
            innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        };
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <aside className="animated">
                <div className="header">
                    <div className="row">
                        <a href="/" className="col align-self-center">
                            <img src="/img/logo.png" width="126" alt="Big Brain Educational" />
                        </a>
                        {/* <div className="flex-row-reverse menu-collapse">
                            <a href="#" className="menu float-right" onClick={this.props.action}>
                                <i className="fas fa-sign-in-alt" />
                            </a>
                        </div> */}
                    </div>
                </div>
                <Nav vertical={true}>
                    {this.menuItens.map(item => (
                        <NavItem key={item.title}>
                            <NavLink
                                tag={Link}
                                to={item.path}
                                title={item.title}
                                onClick={this.setMenu}
                                className={
                                    !this.props.blUsuarioAcessos && this.state.setarMenu === item.title ? 'active' : ''
                                }>                              
                                <span>{item.nome}</span>
                            </NavLink>
                        </NavItem>
                    ))}
                    <NavItem className="dropdown" onClick={this.toggleOpen}>
                        <NavItem
                          className="dropdown-toggle"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          style={{color: 'aliceblue'}}
                        >
                          Dropdown
                        </NavItem>
                        <div className={menuClass} aria-labelledby="dropdownMenuButton">
                        {this.state.name.map(item => (
                            <NavItem key={item.title}>
                                <NavLink
                                    tag={Link}
                                    to={item.path}
                                    title={item.title}
                                    onClick={this.setMenu}
                                    className={
                                        !this.props.blUsuarioAcessos && this.state.setarMenu === item.title ? 'active' : ''
                                    }>                              
                                    <span>{item.nome}</span>
                                </NavLink>
                            </NavItem>
                        ))}
                        </div>
                    </NavItem>
                    {/* <NavItem>
                        <DropDownContent className="" title="Configuração (Uploads)"
                        list={this.state.name}>
                            <i className="fas fa-sliders-h" />
                            <span>Configurações</span>
                        </DropDownContent>
                    </NavItem> */}
                </Nav>
            </aside>
        );
    }
}
export default Sidebar;