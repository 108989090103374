import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
    Card,
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col
} from 'reactstrap';
import ServiceUpload from '../../services/uploads/UploadsServices.js';
import {AlertContext} from "../../providers/AlertProvider";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import UserInfo from "../../core/UserInfo";


class uploadPageMinecraft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmation: false,
            email: "",
            tenantId: ""
        }
    }

    componentDidMount = () => {
        let userInfo = new UserInfo();
        let email = userInfo.Email != undefined ? userInfo.Email : userInfo.UPN;
        this.setState({
            email: email,
            tenantId: userInfo.tudo.profile.tid
        })
    }

    handleInputChange(target) {
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const field = target.name;
        this.setState({
            formFilter: {...this.state.formFilter, [field]: value},
            invalid: {...this.state.invalid, [field]: target.required && !value},
        });
    }

    
    handleInputBlur = target => {
        const field = target.name;
        this.setState({
            invalid: {...this.state.invalid, [field]: target.required && !this.state.formFilter[field]},
        });
    };

    setFile = async (e) => {
        debugger
        var fileUploaded = e.target.files[0];
        var formData = new FormData();
          formData.append('File', fileUploaded);
          let userInfo = new UserInfo();

          let email = this.state.email;
          ServiceUpload.postUploadMine(formData, email, this.state.tenantId, (data) => {
            this.showSuccessMessage("Upload atualizado com sucesso");
            this.setState({showConfirmation: true})

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        }) 

      }

    render() {

        return (
            <main className="h-100 pageminecraft">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <Container fluid className="h-100 animated fadeIn">
                <Button color="primary" className="btn-voltar" onClick={() => window.location.href = "/"} >Voltar</Button>
                    <Row noGutters className="internas">
                        <Col xs={12} sm={12}>
                        <div class="body-container-wrapper">
                            <div class="body-container">
                                <div class="page-center">                
                                    <i class="fa fa-cloud" aria-hidden="true"></i>
                                    <h1>Faça o <strong>Upload</strong> do arquivo <strong>CSV</strong> no botão abaixo</h1>
                                    <label  htmlFor="upload-photo" class="upload" id="call-to-action"><span>Select Your Upload</span></label>
                                    <form id="upload" method="post" action="upload.php" enctype="multipart/form-data">
                                    <div id="drop">
                                        <input  onChange={e => this.setFile(e)} id="upload-photo" type="file" name="upl" multiple  multiple class="upload-hidden"/>
                                    </div>
                                    </form>
                               
                                </div>
                              </div>
                        </div>
                        </Col>
                    </Row>
                </Container>
                <SweetAlert
                    show={this.state.showConfirmation}
                    title="Sucesso"
                    type='success'
                    showCancel
                    showCloseButton
                    confirmBtnText='Ok'
                    confirmButtonColor='#d6486b'
                    confirmBtnCssClass='customBtn'
                    onConfirm={() => this.setState({showConfirmation: false})}
                    showCancel={false}
                    onEscapeKey={() => this.setState({showConfirmation: false})}>
                    Upload CSV Minecraft Realizado com Sucesso
                </SweetAlert>
            </main>
        );
    }
}

export default withRouter(uploadPageMinecraft);