import AADService from "../services/AADService";

class UserInfo {
    constructor(){
        this.aad = new AADService();
    }
    get Username() {
        return this.aad.getUser().profile.name;
    }
    get UPN() {
        return this.aad.getUser().profile.upn;
    }
    get Email() {
        return this.aad.getUser().profile.email;
    }
    get Id() {
        return this.aad.getUser().profile.id;
    }
    get tudo() {
        return this.aad.getUser();
    }
}

export default UserInfo;