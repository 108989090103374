import React from 'react';
import { Nav, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';

export function DropDownContent(props) {
  return (
    
    <a className="dropdown subitem nav-link">
        <a className="dropdown subitem nav-link dropdown-toggle"
          type="button"
          data-toggle="dropdown">
        	<i className="fas fa-sliders-h" /> {props.title}
        </a>
        {props.list.map(item => (
						<NavItem className="dropdown-menu" style={{position: 'relative', backgroundColor: '#112436', border: 'none', WebkitBoxShadow: 'none', BoxShadow: 'none', width: '100%'}} key={item.title}>
							<NavLink
								tag={Link}
								to={item.path}
								title={item.title}
                key={item.id}
								className='dropdown-item'>								
								<span>{item.nome}</span>
							</NavLink>
						</NavItem>
					))}
      </a>
  )
}