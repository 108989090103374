import React, { Component } from 'react';
import {Container} from 'reactstrap';

class PaginaPowerBI extends Component{
    render() {
        return(
            <main className="h-100">
                <Container fluid className="h-100 animated fadeIn">
                    <iframe style={{width: '100%', height: '100%'}} 
                    src="https://app.powerbi.com/view?r=eyJrIjoiMmZiMjUwZmItOWMzMi00NDEwLTg1ZGQtMTcxODYzMWMyM2U0IiwidCI6IjZkNmJjYzNmLWJkYTEtNGY1NC1hZjFkLTg2ZDRiN2Q0ZTZiOCJ9&pageName=ReportSection" 
                    title="POWER BI">
                    </iframe>
                </Container>
            </main>
        )
    }
}

export default PaginaPowerBI;