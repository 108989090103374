import { Component } from 'react';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.itens = [	
			{
				nome: 'Pagina Power BI (Dash)',
				title: 'Pagina Power BI (Dash)',
				path: '/',
				ico: 'fas fa-cubes',
			},
		];
	}

	get Items() {
		return this.itens;
	}
}

export default Menu;
