import React, {Component} from 'react';
import {Container, Col} from 'reactstrap';
import Footer from './Footer';
import Sidebar from './Sidebar';

import AlertProvider from "../../providers/AlertProvider";

class LayoutDefault extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = () => {
        let active = !this.state.collapsed;
        this.setState({collapsed: active});
    };

    render() {
        return (
            <Container fluid style={{height: '100%'}}>
                {/* <Col                    
                    id="sidebar"
                    className={
                        this.state.collapsed
                            ? 'collapsed d-xs-none d-sm-none d-md-none d-lg-block'
                            : 'd-xs-none d-sm-none d-md-none d-lg-block'
                    }>
                    <Sidebar
                        action={this.onCollapse}
                        blUsuarioAcessos={this.props.params && parseInt(this.props.params.idUsuario) > 0}
                    />
                </Col> */}
                    <AlertProvider>
                        {this.props.children}
                    </AlertProvider>
                    <Footer/>
            </Container>
        );
    }
}

export default LayoutDefault;
