import {authContext, endpoint} from '../adalConfig';

export default class AADService {

    login() {
        authContext.login();
    }

    loggedIn() {
        return this.getUser();
    }

    logout() {
        authContext.logOut();
    }

    getToken() {
        return authContext.getCachedToken(endpoint);
    }

    getUser() {
        return authContext.getCachedUser();
    }
}