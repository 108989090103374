import BaseService from '../Base/BaseServices'
import Config from '../../core/constants';
import HttpUtil from '../../core/network/httpUtil';

let Base = new BaseService('Uploads');

class UploadsServices {
    constructor(){
    }

    static services = Base.services;

    static getData(page, pageSize, cb, errorCb) {
        return this.services.getData(page, pageSize, cb, errorCb);
    }
    static postUpload(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadFiles/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static postUploadMine(file, criadoPor, tenantId, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadFileMine/${criadoPor}/${tenantId}`, file,
        response => cb(response.data), errorCb);
    }
    static PostTenantMailboxUsage(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadTenantMailboxUsage/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static PostTenantOfficeLicenses(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadTenantOfficeLicenses/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static PostTenantProductActivity(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadTenantProductActivity/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static PostTenantProductUsage(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadTenantProductUsage/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static PostAlldrs(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadAllDrs/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static PostUserState(file, criadoPor, cb, errorCb){
        return HttpUtil.POST(`${Config.URLS.API}/UploadFile/UploadUserState/${criadoPor}`, file,
        response => cb(response.data), errorCb);
    }
    static GetDatailsCATC(cb, errorCb){
        return HttpUtil.GET(`${Config.URLS.API}/UploadFile/GetDatailsCATC`,
        response => cb(response.data), errorCb);
    }
    static updateData(id, data, cb, errorCb){
        return this.services.updateData(id, data, cb, errorCb);
    }
    static removeData(id, cb, errorCb){
        return this.services.removeData(id, cb, errorCb);
    }
    static saveData(data, cb, errorCb) {
        return this.services.saveData(data, cb, errorCb);
    }
    static getDetails(id, cb, errorCb) {
        return this.services.getDetails(id, cb, errorCb);
    }
    static search(data, cb, errorCb){
        if (data.hasOwnProperty('active')){
            if (typeof data.active !== "boolean"){
                data.active = data.active === 'true';
            }
        }
        data.removed = false;
        return this.services.search(data, cb, errorCb);
    }
    static deleteAttachment = (attachmentId, cb, errorCb) => {        
        let urlApi = process.env.PUBLIC_URL || Config.URLS.API;
        
        return HttpUtil.Delete(`${urlApi}UploadsAttachments/ExcludFile/${attachmentId}`,
                    response => cb(response.data),
                errorCb);
    };
}

export default UploadsServices;